﻿body {background-color: #fff}
.ticklist ul {
	list-style: none;
	padding:0;
  }

  .ticklist ul li {
	  padding-left: 10px;
	  margin-bottom: 10px;
  }
  
  .ticklist ul li:before {
	content: '✓';
	color:#2A2927;
	padding-right: 10px;
  }

  @media only screen and (min-width: 1000px) {
	.ticklist ul
	{
	  column-count: 3;
	}
  }

  @media only screen and (min-width: 800px) {
	.ticklist ul
	{
	column-count: 2;	
	}
}

  .ticklist2 ul {
	list-style: none;
	padding:0;
  }

  .ticklist2 ul li {
	  padding-left: 10px;
	  margin-bottom: 10px;
  }

  .ticklist2 ul li:before {
	content: '✓';
	color:#fff;
	padding-right: 10px;
  }

  @media only screen and (min-width: 1000px) {
	.ticklist2 ul
	{
	  column-count: 3;
	}
  }

  @media only screen and (min-width: 800px) {
	.ticklist2 ul
	{
	column-count: 2;	
	}
}

  .fa-footer {
    color: #fff;
}

.footer-bottom {
	a {
    color: #fff;
	text-decoration: none;
	}
	p {
		color:#fff;
	}
}

.contact-form-block {
	position:relative;


}

.contact-map {
	font-weight: bold;
}

.contact-form-block:after {
    content : "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity : 0.5;
    z-index: -1;
}


@media only screen and (max-width: 600px) {
	.logo img {
		width:100%;
	}
	.fourx-item {
		min-height:200px;
	}
	.cta-image img {
		width:100%;
	}
	.footer-right {
		text-align:center;
	}
	.footer-top {
		text-align:center;
	}
	.jumbotron {
		height:100%;
	}
	.three-x-cta p {
		min-height: 0px;
	}
	.three-x-icon-and-info i  {
		font-size: 28px;
	}
	.privacy-statement {
		width:90%;
	}
}

.margin-20-bottom {
	margin-bottom:20px;
}

.navbar-default .navbar-nav>li>a {
	font-size:16px;
}

.fourx-item a {
	text-decoration: none;
}

.image-block {
	padding-bottom:40px;
}

.alt-block-one {
	background-color: #666;
	padding: 20px 0;
}

.container-fluid {background-color: #ede7e0;}


.jumbotron {
	margin-bottom: 0px;
}

.jumbotron h1, .jumbotron h2, .jumbotron h3, .jumbotron h4, .jumbotron h5, .jumbotron h6 {
    color: #2A2927;
    padding: 10px;
	font-weight: 800;
}

.three-x-cta {
	margin-bottom:20px;

}

.three-x-cta-withimages-block {
	padding-bottom:20px;
}

.contact-form-block {
	padding-top:20px;
    color: #fff;
	background-color: #802049 ;
	/*background-image: url('/media/1874/contact_bg2.jpg');*/
	background-image: url('/media/2025/contact_bg_blue.png');
	background-repeat: no-repeat;
	background-size: cover;
	a {
		color: #fff;
	}
	h1,h2,h3,h4 {color: #fff }
	h4 {font-size:30px;}
	#btnSendContactUs {
		color:#2A2927
	}
}

.three-x-cta-iconandinfo-block {
	background-color:  #5C7FCC;
	h4 {color: #fff}
	.fa {color: #fff}
	p {color:#fff}
}

.four-x-cta-block {
	padding-top: 20px;
}

.privacy-statement {
	font-size:10px;
}

.header .fa {
	color:#9DCC48
}

.header-content .adr {
	color:#9DCC48;
	font-size: 16px;
}

.footer-logos {
	margin: 15px 0;
}

.cta-section {
	background-color: #999;
    padding: 20px 0 30px 0;
}

.header-top-container {
	color: #9DCC48;
	a {
		color:#9DCC48;
	}
}

.three-x-cta a {
	margin-bottom:10px;
}

.width-100 {
	width:100%;
}

.header-email {font-size:14px;}

.content-block-highlight {
	/*background-color: #5C7FCC;*/
	background-color: #222f42;
	padding: 5px 15px 20px 15px;
	color:#fff;
	h1,h2,h3,h4 {color:#fff}
	p {color:#fff}
	a {
		color:#fff;
	}
	.ticklist ul li:before {
		color:#fff;
	  }
	
}

.content-block-highlight2 {
	background-color: #2A2927;
	padding: 5px 15px 20px 15px;
	color:#fff;
	h1,h2,h3,h4 {color:#fff}
	p {color:#fff}  
}



.p-logo {
	width:170px;
	float:left;
	padding: 0 5px 0 0 
}

.professional-logos {
	float:right;
}


@media only screen and (max-width: 400px) {
	.p-logo {
		padding:  0 ;
		width: 50%;
	}
	.header-content-tel 
	{
		text-align:center;
		margin: 0 auto;
		width: 100%;
		font-size:24px;
	}
}



@media only screen and (max-width: 600px) {
	.professional-logos {
		margin-left: 0px; 		
	}
	.p-logo {
		padding: 5px 0 5px 0 ;
		text-align: center;
	}

}

@media only screen and (max-width: 1000px) {
	.header-content-tel 
	{
		text-align:center;
		margin: 0 auto;
		width: 100%;
		margin-bottom:10px;

	}
	.logo {
		img {width: 100%}
	}
	.professional-logos {
		margin-left: 40px; 		
		float: none;
	}
	.vcard {
		text-align: center;
	}
	.footer-tel-email {
		text-align: center;
	}
}

@media only screen and (max-width: 600px) {
	.professional-logos {
		display: none;
	}
	.header-content-tel {
		font-size: 14px;
	}
}


.header-top-m {
	display:none;
	text-align:center;
}

.company-reg {
	float: right;
    font-size: 14px;
    width: 250px;
    clear: both;
    margin-top: 5px;
}


@media only screen and (max-width: 1000px) {
	.header-top-m {
		display: block;
		padding: 5px 0;
	}
	.header-top-dt {
		display: none;
	}	
	.owl-pagination {
		display:none;
	}
	.jumbotron h1, .jumbotron h2, .jumbotron h3, .jumbotron h4, .jumbotron h5, .jumbotron h6 {
		margin-left: 0;
	}
	.footer-right {
	font-size: 12px;
	}
	.company-reg {
		margin-top:3px;
		text-align:center;
		width:100%
	}

	.company-reg {
		font-size: 10px;
	}

}

@media only screen and (min-width: 1000px) {
	.cta3-content-inner {
		height:150px;
	}
}



/*Distinction Pages */
.calendar-head h2 {    
    height: 120px;
    padding: 30px 0 0 20px;
    color: #fff;
    font-size: 48px;
} 

.spring-head h2 {
    background-image: url('/media/1614/spring-banner.jpg');
}


.summer-head h2 {
    background-image: url('/media/1615/summer-banner.jpg');
}


.autumn-head h2 {
    background-image: url('/media/1613/autumn-banner.jpg');
}


.winter-head h2 {
    background-image: url('/media/1616/winter-banner.jpg');
}

.calendar .three-x-cta-text-only h4 {
    font-size: 35px;
    line-height: 40px;
}

.calendar .three-x-cta-text-only h4::before {
    content: url('/media/1617/calendar-icon-40png.png');
    padding-right:10px;
}

.calendar ul li {
    margin-bottom:10px;
}