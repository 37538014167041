﻿.three-x-cta-text-only {
     h4 {
        font-size: 22px;
        line-height: 25px;
    }
    p {

    }
    .content {
        padding: 0 15px;
        overflow: hidden;
    }
}