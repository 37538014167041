﻿/*footer area*/
.footer-outer .footer-top {
    padding: 20px 0;
    background: $footer-bg-colour;
    font-size: 18px;
    }

.footer-outer .fa {
    margin-right:5px;
}

.footer-bottom {
    background: $footer-inner-bg-colour;
    color:$footer-color;
    font-size: 14px;
    border-top: none;
    padding: 10px 0 25px;
}

.footer-mid {
    background: $footer-inner-bg-colour;
}

.footer-outer {
    color: $footer-body-colour;
    background: $footer-bg-colour;
    h1,h2,h3,h4,h5,h6 {
        color: $footer-header-colour;
    }
    a {
        color:$footer-color-a;
    }
    a:hover {
        color:$footer-color-hover
    }
    a:visited {
        color:$footer-color-visited;
    }
    a:focus {
        color:$footer-color-focus;
    }
    border-top: none;
	    padding-left:15px;
}

.footer-right {
    text-align:right;
}
/*Logo*/

/*Content*/

/*Links*/

/*Company Details*/