﻿.counter 
{
    font-size:48px

}

.counter-prefix
{
    font-size:48px

}