﻿.dots 
{
	position: absolute;
	bottom: 5px;
	left: 0;
	right: 0;
	z-index: 1;
	margin: 0;
	border: 0;
	height: 3px;
	display: block;
	background-image: radial-gradient(circle closest-side,#b3b3b3 99%,rgba(0,0,0,0) 1%);
	background-position: bottom;
	background-size: 6px 3px;
	background-repeat: repeat-x;
}


.pricelist {
	list-style-type: none;
	padding: 0;
	margin: 0 0 10px 0;
}

.pricelist li {

}

.pricelist-item {
    padding: 10px;
    background-color: #bababa;
    margin-bottom: 6px;

    h4 {
        margin: 0 0 6px 0;
    }
}

.pricelist-item-callout
{
	border-style: solid;
	border-width: 1px;
	border-color: #991E75;
	
	
}

.pricelist-callout {
	background-color: #991E75;
	padding:4px;
	color: #fff;
}

.pricelist-price {
	font-weight:800;
}

.pricelist-description {}
