﻿/*3x Call To Action*/
.three-x-icon-and-info { 
    i {
        float: left;
        padding: 10px;
        font-size: 42px;
        text-align: center;
        -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
    }
    h4 {
        font-size: 22px;
        line-height: 25px;
    }
    p {

    }
    .content {
        padding: 0 15px;
        overflow: hidden;
    }
} 