﻿.jumbotron {
    min-height: 400px;
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: center top;
    background-size: cover;
    background-position: center;


    h1,h2,h3,h4,h5,h6 {
        color: #fff;
        padding: 10px;
        background-color: rgba(255,255,255,.55);
        margin-left:10%;
    }
    a {
        color:$banner-color-a;
    }
    a:hover {
        color:$banner-color-hover
    }
    a:visited {
        color:$banner-color-visited;
    }
    a:focus {
        color:$banner-color-focus;
    }
}