﻿.three-x-cta {

     h4 {
        font-size: 22px;
        line-height: 25px;
    }
    p {

    }
    .content {
        padding: 0 15px;
        overflow: hidden;
    }

} 

@media only screen and (max-width: 960px) {
.three-x-cta {
    padding-top:10px 
    }
}

.cta-image {
    border:0;
    padding:0;
    margin:0;
}

