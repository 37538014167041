﻿.header {
    .logo img {
        padding:10px;
    }
    h5.strapline {
        font-size: $strapline-font-size;
        padding-bottom: 10px;
        border-bottom: $strapline-colour solid 1px;
    }
    
 }
 .header-content {
        color: $header-colour;
        font-size: $header-font-size;
        padding:10px;
    }
 .header-content-address {
     font-size:24px;
 }


 .header-content-tel .fa {
     margin-right:10px;
 }



@media screen and (min-width: 768px) {
    .navbar {

        border-radius: $navbar-border-radius;
    }

}



/* Top bar with contact details */
.header-top-container {
	color: $header-text-colour;
	background-color: $header-bg-colour !important;
	a {
		color:$header-text-colour;
	}
	.fa {
		color: $header-text-colour;
	}
}

 .header-content {
        color: $header-text-colour;
        font-size: $header-font-size;
        padding:10px;
    }
 .header-content-address {
     font-size:24px;
 }
.header-content .adr {
	color:$header-text-colour;
	font-size: 16px;
}


/* Additional Navbar styling */

@media screen and (min-width: 768px) {
    .navbar {

        border-radius: $navbar-border-radius;
    }

}

.navbar-default .navbar-nav>li>a {
	font-size:$navbar-font-size !important;
}



